import map from "./map";

import.meta.glob(["@images/**"]);
import.meta.env.DEV && import("@styles/main.sass");

import { createApp, reactive } from "petite-vue";
import Swiper from "swiper";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import SimpleLightbox from "simple-lightbox";
import tippy, { followCursor, hideAll } from "tippy.js";

interface Header {
  opened: boolean;
  handleOpenedHeader(state: boolean): void;
}

const headerEl = document.querySelector("[data-header]"),
  headerScope: Header = {
    opened: false,
    handleOpenedHeader(state) {
      document.body.classList.toggle("u-hiddenOverflow", state);
    },
  };

headerEl && createApp(headerScope).mount(headerEl);

(async () => {
  const video = document.querySelector("[data-hero-video]") as HTMLVideoElement;
  if (!video) return;

  const { gsap } = await import("gsap"),
    { ScrollTrigger } = await import("gsap/ScrollTrigger");

  gsap.registerPlugin(ScrollTrigger);

  const start = video.getBoundingClientRect().top + window.scrollY;

  let tl = gsap.timeline({
    defaults: { duration: 1 },
    scrollTrigger: {
      trigger: "[data-hero-video]",
      start: `top ${start}px`,
      end: "bottom top",
      scrub: true,
    },
  });

  function once(el: HTMLElement, event: string, fn: () => void, opts = {}) {
    const onceFn = function () {
      el.removeEventListener(event, onceFn);
      fn.apply(this, arguments);
    };

    el.addEventListener(event, onceFn, opts);
    return onceFn;
  }

  once(document.documentElement, "touchstart", () => {
    video.play();
    video.pause();
  });

  const setUpVideoTimeline = () => {
    tl.fromTo(video, { currentTime: 0 }, { currentTime: video.duration || 1 });
  };

  if (video.readyState > 0) {
    setUpVideoTimeline();
  } else {
    once(video, "loadedmetadata", setUpVideoTimeline);
  }
})();

declare global {
  interface Window {
    mapLoaded: boolean;
    initMap: () => void;
  }

  const archiveData: {
    apiUrl: string;
    availableCount: number;
    title: string;
    totalCount: number;
    budova: string;
    podlazi: string;
    dispozice: string;
    prostory: string;
    dostupnost: string;
    offers: string[];
    page: number;
    nextPageUrl: string | null;
    pager: string;
    query: Object;
    order: string;
    appendPosts: string;
  };

  const mapHotspots: {
    coordinates: string;
    icon: string;
    text: string;
    title: string;
  }[];
}

if (document.body.classList.contains("post-type-archive-offer")) {
  const Archive = reactive({
    apiUrl: archiveData?.apiUrl ?? null,
    isLoading: false,
    appendPosts: false,
    isQueryChanged: !!Object.keys(archiveData?.query ?? {}).length,
    availableCount: archiveData?.availableCount ?? 0,
    title: archiveData?.title ?? "Nabídka bytů",
    budova: archiveData?.budova ?? "nerozhoduje",
    podlazi: archiveData?.podlazi ?? "nerozhoduje",
    dispozice: archiveData?.dispozice ?? "nerozhoduje",
    dostupnost: archiveData?.dostupnost ?? "nerozhoduje",
    query: archiveData?.query ?? {},
    totalCount: archiveData?.totalCount ?? 0,
    offers: archiveData?.offers ?? [],
    order: archiveData?.order ?? "",
    page: archiveData?.page ?? 1,
    nextPageUrl: archiveData?.nextPageUrl ?? null,
    pager: archiveData?.pager ?? "",

    setQueryFromUrl(query: string) {
      const urlQuery = new URLSearchParams(query);

      this.budova = urlQuery.get("budova") ?? "nerozhoduje";
      this.podlazi = urlQuery.get("podlazi") ?? "nerozhoduje";
      this.dispozice = urlQuery.get("dispozice") ?? "nerozhoduje";
      this.dostupnost = urlQuery.get("dostupnost") ?? "nerozhoduje";
      this.page = urlQuery.get("strana") ?? 1;

      this.loadArchive(false, true);
    },
    setBuilding(building = "") {
      this.budova = building !== "" ? building : "nerozhoduje";
      this.loadArchive(true);
    },
    setFloor(floor = 0) {
      this.podlazi = floor !== 0 ? floor : "nerozhoduje";
      this.loadArchive(true);
    },
    toggleOrder() {
      if (this.isLoading) return;

      if (this.order === "nejdrazsi") {
        this.order = "nejlevnejsi";
      } else if (this.order === "nejlevnejsi") {
        this.order = "";
      } else {
        this.order = "nejdrazsi";
      }

      this.loadArchive();
    },
    changePage(page: number) {
      this.page = page;
      this.loadArchive();
    },
    loadNextPage() {
      this.appendPosts = true;
      this.page = this.page + 1;
      this.loadArchive();
    },
    prepareQueryString(resetPager: false) {
      const query = new URLSearchParams({
        budova: this.budova,
        podlazi: this.podlazi,
        dispozice: this.dispozice,
        dostupnost: this.dostupnost,
        razeni: this.order,
        strana: resetPager ? 1 : this.page,
      });

      [...query.entries()].forEach(
        ([key, value]) =>
          (!value || value === "nerozhoduje") && query.delete(key)
      );
      query.get("strana") === "1" && query.delete("strana");

      return [...query].length
        ? `?${decodeURIComponent(query.toString())}`
        : "";
    },
    async loadArchive(resetPager: false, noHistory: false) {
      this.isLoading = true;

      const queryString = this.prepareQueryString(resetPager),
        targetUrl = `${location.pathname}${queryString}`;

      await fetch(`${this.apiUrl}${queryString}`)
        .then((response) => response.json())
        .then((data) => {
          this.query = data.query;
          this.offers = [
            ...(this.appendPosts ? this.offers : []),
            ...data.offers,
          ];
          this.title = data.title;
          this.availableCount = data.availableCount;
          this.totalCount = data.totalCount;
          this.page = data.page;
          this.pager = data.pager;
          this.nextPageUrl = data.nextPageUrl;

          !noHistory && history.pushState({}, "", targetUrl);
        })
        .catch(() => location.assign(targetUrl))
        .finally(() => {
          this.isLoading = this.appendPosts = false;
        });
    },
    async loadPreview() {
      this.isQueryChanged = true;

      const queryString = this.prepareQueryString();
      await fetch(`${this.apiUrl}-preview${queryString}`)
        .then((response) => response.json())
        .then((data) => {
          this.totalCount = data.count;
        });
    },
  });

  createApp({ Archive }).mount("[data-archive-hero]");
  createApp({ Archive }).mount("[data-offer-filter]");
  createApp({ Archive }).mount("[data-offer-list]");

  window.addEventListener("popstate", (e) => {
    const target = e.target as Window;
    if (!target) return;

    Archive.setQueryFromUrl(target.location.search);
  });

  const pager = document.querySelector("[data-pager]");
  pager.querySelector("nav")?.addEventListener("click", (e) => {
    const target = e.target as HTMLElement;
    if (!target.matches("a")) return;

    e.preventDefault();
    const pageUrl = new URLSearchParams(
      new URL(target.getAttribute("href")).search
    );
    Archive.changePage(parseInt(pageUrl.get("strana") ?? "1"));
  });
}

(() => {
  const slider = document.querySelector(
    "[data-timeline-slides]"
  ) as HTMLElement;
  if (!slider) return;

  new Swiper(slider, {
    slidesPerView: 1,
    spaceBetween: 24,
    initialSlide: parseInt(slider.dataset?.current ?? "0"),
    breakpoints: {
      768: {
        slidesPerView: 2,
        initialSlide: parseInt(slider.dataset?.current ?? "0"),
      },
      1024: {
        slidesPerView: 3,
        initialSlide: parseInt(slider.dataset?.current ?? "0") - 1,
      },
    },
    allowTouchMove: true,
    modules: [Navigation],
    navigation: {
      nextEl: "[data-timeline-next]",
      prevEl: "[data-timeline-prev]",
    },
  });
})();

(() => {
  if (!document.querySelector(".wp-block-gallery")) return;

  new SimpleLightbox({
    elements: ".wp-block-gallery figure > a",
  });
})();

(() => {
  if (!document.querySelector("[data-gallery]")) return;

  new SimpleLightbox({
    elements: "[data-gallery] > a",
  });
})();

(() => {
  if (!document.querySelector("[data-lightbox]")) return;

  new SimpleLightbox({
    elements: "[data-lightbox] > a",
  });
})();


(() => {
  const originalPushState = history.pushState;
  const originalReplaceState = history.replaceState;

  history.pushState = function () {
    originalPushState.apply(this, arguments);
    hideAll();
  };

  history.replaceState = function () {
    originalReplaceState.apply(this, arguments);
    hideAll();
  };

  window.addEventListener("popstate", () => {
    hideAll();
  });
})();


(() => {
  const prepBuildingTippyContent = (reference: HTMLElement) => {
    console.log("Initializing building tooltip", reference);
    const data = reference.dataset.buildingTippy.split(";");
    return `<b class="u-kalnia">${reference.getAttribute(
      "aria-label"
    )}</b><br><small>Byty k prodeji</small><br><span>${
      data[0]
    }x volný</span><br><span>${data[1]}x rezervovaný / prodaný</span>`;
  };

  tippy("[data-building-tippy]", {
    content: (reference) => prepBuildingTippyContent(reference as HTMLElement),
    arrow: false,
    allowHTML: true,
    followCursor: true,
    theme: "light",
    plugins: [followCursor],
  });

  const hideTooltipsOnInteraction = () => {
    hideAll();
  };

  document.addEventListener("click", (event) => {
    const target = event.target as HTMLElement;
    if (target.tagName === "A" || target.tagName === "BUTTON") {
      hideTooltipsOnInteraction();
    }
  });

  document.addEventListener("touchstart", (event) => {
    const target = event.target as HTMLElement;
    if (target.tagName === "A" || target.tagName === "BUTTON") {
      hideTooltipsOnInteraction();
    }
  });

  window.addEventListener("popstate", () => {
    hideAll();
  });

  window.addEventListener("beforeunload", () => {
    hideAll();
  });

  document.addEventListener("DOMContentLoaded", () => {
    hideAll();
  });
})();

(() => {
  const prepOfferTippyContent = (reference: HTMLElement) => {
    console.log("Initializing offer tooltip", reference);
    const data = reference.dataset.offerTippy.split(";"),
      colorClass =
        reference.dataset.availability === "volny"
          ? "c-primary"
          : "c-secondary";

    return `<span class="${colorClass}">${
      data[0]
    }</span><br><b class="u-kalnia">${reference.getAttribute(
      "aria-label"
    )}</b><br><small>${data[1]} — ${data[2]} m<sup>2</sup></small>`;
  };

  tippy("[data-offer-tippy]", {
    content: (reference) => prepOfferTippyContent(reference as HTMLElement),
    arrow: false,
    allowHTML: true,
    followCursor: true,
    theme: "light",
    plugins: [followCursor],
  });

  const hideTooltipsOnInteraction = () => {
    hideAll();
  };

  document.addEventListener("click", (event) => {
    const target = event.target as HTMLElement;
    if (target.tagName === "A" || target.tagName === "BUTTON") {
      hideTooltipsOnInteraction();
    }
  });

  document.addEventListener("touchstart", (event) => {
    const target = event.target as HTMLElement;
    if (target.tagName === "A" || target.tagName === "BUTTON") {
      hideTooltipsOnInteraction();
    }
  });

  window.addEventListener("popstate", () => {
    hideAll();
  });

  window.addEventListener("beforeunload", () => {
    hideAll();
  });

  document.addEventListener("DOMContentLoaded", () => {
    hideAll();
  });
})();

document.addEventListener("DOMContentLoaded", () => {
  (() => {
    const dialog = document.querySelector(
      "[data-modal-form]"
    ) as HTMLDialogElement;
    if (!dialog) return;

    document
      .querySelectorAll("[data-modal-opener]")
      .forEach((el: HTMLElement) =>
        el.addEventListener("click", () => {
          document.body.classList.add("u-hiddenOverflow");
          dialog.showModal();
        })
      );

    dialog
      .querySelector("[data-modal-form-closer]")
      ?.addEventListener("click", () => dialog.close());
    dialog.addEventListener(
      "click",
      (ev) => ev.target === dialog && dialog.close()
    );
    dialog.addEventListener("close", () =>
      document.body.classList.remove("u-hiddenOverflow")
    );
  })();

  (() => {
    // Count up numbers
    const animationDuration = 2400;
    const frameDuration = 1000 / 60;
    const totalFrames = Math.round(animationDuration / frameDuration);
    const easeOutQuad = (t) => t * (2 - t);

    const animateCountUp = (el: HTMLElement) => {
      if (el.classList.contains("is-counted")) return;

      el.classList.add("is-counted");
      el.innerHTML = el.innerHTML.replace(/([0-9]+)/g, "<span>$1</span>");
      let frame = 0;

      const number = el.querySelector("span");
      if (number.nextElementSibling?.nodeName === "SPAN") {
        number.innerHTML += number.nextElementSibling.innerHTML;
        number.nextElementSibling.remove();
      }

      const countTo = parseInt(number.innerHTML, 10);
      const counter = setInterval(() => {
        frame++;
        const progress = easeOutQuad(frame / totalFrames),
          currentCount = Math.round(countTo * progress);

        if (parseInt(number.innerHTML, 10) !== currentCount)
          number.innerHTML = currentCount.toLocaleString();

        frame === totalFrames && clearInterval(counter);
      }, frameDuration);
    };

    function isElementInViewport(el: HTMLElement) {
      const rect = el.getBoundingClientRect();

      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight ||
            document.documentElement
              .clientHeight) /* or $(window).height() */ &&
        rect.right <=
          (window.innerWidth ||
            document.documentElement.clientWidth) /* or $(window).width() */
      );
    }

    const runCountUp = () => {
      document
        .querySelectorAll("[data-about-number]")
        .forEach((el: HTMLElement) => {
          isElementInViewport(el) && animateCountUp(el);
        });
    };

    runCountUp();
    window.addEventListener("scroll", runCountUp);
  })();
});

(() => {
  window.mapLoaded = false;

  const isInViewport = (el, percentVisible) => {
    if (!el) return false;

    let rect = el.getBoundingClientRect(),
      windowHeight =
        window.innerHeight || document.documentElement.clientHeight;

    return !(
      Math.floor(100 - ((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100) <
        percentVisible ||
      Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) <
        percentVisible
    );
  };

  const loadMap = () => {
    if (
      window.mapLoaded ||
      !isInViewport(document.querySelector("[data-map-holder]"), 4)
    )
      return;

    window.mapLoaded = true;

    const gMapsJs = document.createElement("script");
    gMapsJs.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA_IckNle5OAQHtUqaSKFQHDtGe9nmJj_0&loading=async&callback=initMap&libraries=&v=weekly`;

    document.body.insertAdjacentElement("beforeend", gMapsJs);
  };

  document.addEventListener("DOMContentLoaded", loadMap);
  document.addEventListener("scroll", loadMap);
})();

window.initMap = map;
